import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus, faPen } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useRef } from 'react';

const Customer = ({ customer, getDatas, addSuccess, clearDatas }) => {

  const [hour, setHour] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [dateEditor, setDateEditor] = useState(false);
  const [copying, setCopying] = useState(false);
  const [editedDate, setEditedDate] = useState(customer.date.replaceAll("-", "/"));

  const copyInput = useRef(null);
  const sectionDateEditor = useRef(null);

  const dateEditorCloseAnimation = () => {
    sectionDateEditor.current.classList.remove("visible");

    let timeout = window.setTimeout((ev) => {
      if(sectionDateEditor.current){
        sectionDateEditor.current.style.display = "none";
      }
      
      setDateEditor(false)

      return ()=> {
        clearTimeout(timeout);
      }
    }, 500)
  }


  const handlerCheck = (ev) => {
    if (window.confirm("Voulez-vous vraiment confirmer le rendez-vous de " + customer.prenom + " " + customer.nom.toUpperCase() + " ?")) {
      axios.get("https://connivence-salon.net/api/lsbdx/confirm.php?id=" + customer.client_id)
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
          }
          else if (res.data.success) {
            console.log(res.data.success);

            getDatas();

            addSuccess("Le rendez-vous de " + customer.prenom + " " + customer.nom.toUpperCase() + " a bien été confirmé, un mail lui a été envoyé !");
          }

        })
    }
  }

  const handlerEdit = (ev) => {
    setDateEditor(true);
  }

  const handlerClose = (ev) => {
    if (ev.target.tagName === "SECTION") {
      dateEditorCloseAnimation();
    }
  }

  const handlerSaveDate = (ev) => {
    ev.preventDefault();

    let newDate = editedDate;

    if (newDate !== customer.date) {
      if (newDate.indexOf("T") > -1) {
        newDate = newDate.replace("T", " ") + ":00";
      }



      axios.get("https://connivence-salon.net/api/lsbdx/date.php?id=" + customer.id + "&date=" + newDate.replaceAll("/", "-"))
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
          }
          else if (res.data.success) {
            console.log(res.data.success);

            dateEditorCloseAnimation();

            addSuccess("La date du rendez-vous de " + customer.prenom + " " + customer.nom.toUpperCase() + " a bien été modifiée !");

            clearDatas();

            getDatas();
          }

        })
    }
    else {
      dateEditorCloseAnimation();

      addSuccess("La date du rendez-vous de " + customer.prenom + " " + customer.nom.toUpperCase() + " a bien été modifiée !");
    }


  }

  const handlerCopy = (ev) => {
    if (copying === false) {
      setCopying(true);
      const copiedValue = ev.target.innerHTML
      copyInput.current.value = copiedValue;
      copyInput.current.select();
      copyInput.current.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyInput.current.value);
      copyInput.current.blur();

      ev.target.innerHTML = "<span>Copié dans le presse-papiers !</span>"

      let timeout = window.setTimeout(() => {
        ev.target.innerHTML = copiedValue;
        setCopying(false);

        return ()=> {
          clearTimeout(timeout);
        }
      }, 1000)
    }

  }

  const handlerDelete = (ev) => {
    if (window.confirm("Voulez-vous vraiment supprimer le rendez-vous de " + customer.nom.toUpperCase() + " " + customer.prenom + " ?")) {
      axios.get("https://connivence-salon.net/api/lsbdx/delete.php?id=" + customer.client_id)
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
          }
          else if (res.data.success) {
            console.log(res.data.success);
            getDatas();

            addSuccess("Le rendez-vous de " + customer.nom.toUpperCase() + " " + customer.prenom + " a bien été supprimé !");
          }

        })
    }
  }

  useEffect(() => {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC"
    ]
    const dateBrut = new Date(customer.date.replaceAll("-", "/"));
    setHour(("0" + dateBrut.getHours()).slice('-2') + ":" + ("0" + dateBrut.getMinutes()).slice('-2'));
    setDay(("0" + dateBrut.getDate()).slice('-2'));
    setMonth(months[dateBrut.getMonth()]);

    if (dateEditor) {
      sectionDateEditor.current.style.display = "flex";

      let timeout = window.setTimeout((ev) => {
        sectionDateEditor.current.classList.add("visible");
        return ()=> {
          clearTimeout(timeout);
        }
      }, 10)
    }
  }, [customer, hour, day, month, dateEditor, editedDate])


  return (
    <li className='customer'>
      <div className='date'>
        {month}
        <span>{day}</span>
      </div>
      <div className='infos'>
        <p><strong>{customer.nom.toUpperCase()} {customer.prenom}</strong></p>
        <p><strong>{hour}</strong></p>
        <p className='copy' onClick={handlerCopy}>{customer.telephone}</p>
        <p className='copy' onClick={handlerCopy}>{customer.email}</p>
      </div>

      <ul className='actions' data-customer={customer.client_id} data-stage={customer.id}>
        {customer.paid === "0" ?
          <li key="action_1" className='action-check' onClick={handlerCheck}>
            <FontAwesomeIcon icon={faCheck} />
          </li>
          : ""}
        <li key="action_2" className='action-edit' onClick={handlerEdit}>
          <FontAwesomeIcon icon={faPen} />
        </li>
        <li key="action_3" className='action-remove' onClick={handlerDelete}>
          <FontAwesomeIcon icon={faMinus} />
        </li>
      </ul>

      {dateEditor === true ?
        <section className='date-editor' style={{ display: "none" }} ref={sectionDateEditor} onClick={handlerClose}>
          <form onSubmit={handlerSaveDate}>
            <h3>Changer la date de ce rendez-vous :</h3>
            <input type="datetime-local" defaultValue={editedDate.replace(" ", "T").replaceAll("/", "-")} onChange={(ev) => (setEditedDate(ev.target.value))} />
            <input type="hidden" value={customer.id} />
            <input type="submit" value="Modifier" />
          </form>
        </section>
        : ""}

      <input type="text" ref={copyInput} className='copy-input' />
    </li>
  );
};

export default Customer;