import axios from 'axios';
import React, { } from 'react';
import { useRef } from 'react';

const ProspectForm = ({ prospect, getDatas, closeEditor, addSuccess, clearDatas }) => {


  //const [popupOpened, setPopupOpened] = useState(false);
  const formEl = useRef();

  const handlerSubmit = (ev) => {
    ev.preventDefault();

    axios.get("https://connivence-salon.net/api/lsbdx/prospects/edit.php?prenom=" + encodeURIComponent(formEl.current.elements[1].value) + "&nom=" + encodeURIComponent(formEl.current.elements[0].value) + "&mail=" + encodeURIComponent(formEl.current.elements[2].value) + "&telephone=" + encodeURIComponent(formEl.current.elements[3].value) + "&commentaire=" + encodeURIComponent(formEl.current.elements[4].value) + "&id=" + encodeURIComponent(formEl.current.elements[5].value))
      .then((res) => {
        if (res.data.success) {
          clearDatas();
          getDatas();
          closeEditor(formEl.current.parentNode.parentNode);
          if (formEl.current.elements[5].value === "0") {
            addSuccess("La fiche prospect de " + formEl.current.elements[0].value.toUpperCase() + " " + formEl.current.elements[1].value + " a bien été créée !");
          }
          else {
            addSuccess("La fiche prospect de " + formEl.current.elements[0].value.toUpperCase() + " " + formEl.current.elements[1].value + " a bien été modifiée !");
          }
        }
        else if (res.data.error) {
          console.log(res.data.error);
        }

      })
  }

  const handlerWriteMail = (ev) => {
    formEl.current.elements[2].value = formEl.current.elements[2].value.toLowerCase().replaceAll(" ", "");
  }

  return (
    <form ref={formEl} onSubmit={handlerSubmit} >
      <div className='form-content mid-width'>
        <label htmlFor='nom'>Nom :</label>
        <input type='text' name='nom' id='nom' defaultValue={prospect === 0 ? "" : prospect.nom} required />
      </div>
      <div className='form-content mid-width'>
        <label htmlFor='prenom'>Prénom :</label>
        <input type='text' name='prenom' id='prenom' defaultValue={prospect === 0 ? "" : prospect.prenom} required />
      </div>
      <div className='form-content mid-width'>
        <label htmlFor='mail'>Adresse mail :</label>
        <input type='mail' name='mail' id='mail' defaultValue={prospect === 0 ? "" : prospect.mail} onChange={handlerWriteMail} />
      </div>
      <div className='form-content mid-width'>
        <label htmlFor='telephone'>Telephone :</label>
        <input type='text' name='telephone' id='telephone' defaultValue={prospect === 0 ? "" : prospect.telephone} />
      </div>
      <div className='form-content full-width'>
        <label htmlFor='commentaire'>Commentaire :</label>
        <textarea name='commentaire' id='commentaire' defaultValue={prospect === 0 ? "" : prospect.commentaire}></textarea>
      </div>
      <input type='hidden' name='id' value={prospect === 0 ? "0" : prospect.id} />
      <input type='submit' value={prospect === 0 ? "Ajouter" : "Modifier"} />
    </form>
  );
};

export default ProspectForm;