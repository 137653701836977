import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';

const Hour = (hour) => {

  const handlerEdit = (ev) => {

    if (hour.hour.nom) {
      window.alert("Vous ne pouvez pas modifier ce rendez-vous puisqu'il a été pris par un client sur le site.");
    }
    else {
      const value = ev.target.classList.contains("removed") ? 0 : 1;

      axios.get("https://connivence-salon.net/api/lsbdx/creneaux/update.php?id=" + hour.hour.id + "&value=" + value)
        .then((res) => {
          if (res.data.success) {
            ev.target.classList.toggle("removed");
          }
          else if (res.data.error) {
            console.log(res.data.error);
          }

        })
    }

  }

  return (
    <li>
      <button className={hour.hour.nom || hour.hour.full === "1" ? "removed" : ""} onClick={handlerEdit} data-id={hour.hour.id}>
        <span className='check-case'>
          <span className='check-content'>
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </span>
        {hour.hour.date}
      </button>
    </li>
  );
};

export default Hour;