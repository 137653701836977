import React, { useRef, useState } from 'react';

const Login = ({ logged, loginTry }) => {

  const [error, setError] = useState(false);

  const inputPassword = useRef(null);

  const handlerSubmit = (ev) => {
    if (inputPassword.current.value.length > 0) {
      let result = loginTry(inputPassword.current.value);

      if (result === false) {
        setError(true);
      }
      else {
        inputPassword.current.blur();
      }
    }

  }

  return (
    <section className={'login-panel'}>

      <form onSubmit={(ev) => ev.preventDefault()}>

        <label htmlFor='password'>Mot de passe :</label>

        <input type='password' name='password' id='password' onInput={() => setError(false)} ref={inputPassword} required autoComplete='on' />

        {error === true ?
          <p className='error-red'>Le mot de passe est incorrect</p>
          : ""}

        <button className='try-login' onClick={(ev) => handlerSubmit()}>Connexion</button>

      </form>

    </section>
  );
};

export default Login;