import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Header from '../components/Header';
import Hour from '../components/Hour';

const Remove = (getDatas) => {

  const [hours, setHours] = useState([]);

  useEffect(() => {
    axios.get("https://connivence-salon.net/api/lsbdx/creneaux/get.php")
      .then((res) => {
        if (res.data.success) {
          setHours(res.data.datas)
        }
        else if (res.data.error) {
          console.log(res.data.error);
        }

      })
  }, [])

  return (
    <>
      <Header getDatas={getDatas} />
      <main className='remove'>
        <ul>
          {hours?.map((hour, index) =>
            <Hour key={index} hour={hour} />
          )}
        </ul>
      </main>
    </>
  );
};

export default Remove;