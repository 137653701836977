import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import Header from '../components/Header';
import Prospect from '../components/Prospect';
import ProspectForm from '../components/ProspectForm';
import Success from '../components/Success';

const Prospects = ({ successList, addSuccess, removeSuccess, editSuccess }) => {

  const [prospectsDatas, setProspectDatas] = useState({
    error: "The request was not sent - error0",
    content: []
  });

  const [prospectSaves, setProspectSaves] = useState();
  const [searchContent, setSearchContent] = useState("");
  const [popupOpened, setPopupOpened] = useState(false);
  const [popupState, setPopupState] = useState(0);

  const sectionProspectEditor = useRef();

  const getDatas = () => {
    axios.get("https://connivence-salon.net/api/lsbdx/prospects/get.php")
      .then((res) => {
        if (res.data.success) {
          setProspectDatas(res.data);
          setProspectSaves(res.data);
        }
        else if (res.data.error) {
          console.log(res.data.error);
        }

      })
  }

  const clearDatas = () => {
    setProspectDatas([]);
  }

  const changePopupState = () => {
    if (popupState === 0) {
      setPopupState(1);
      return true;
    }
    else {
      return false;
    }
  }

  const openEditor = (ev) => {

    let popupResponse = changePopupState();

    if (popupResponse === true) {
      setPopupOpened(true);
    }

  }

  const popupEditorCloseAnimation = (el) => {
    el.classList.remove("visible");

    let timeout = window.setTimeout(() => {
      if (el.current) {
        el.style.display = "none";
      }

      setPopupState(0);

      return () => {
        clearTimeout(timeout);
      }

    }, 500)
  }

  const closeEditor = () => {
    popupEditorCloseAnimation(sectionProspectEditor.current);

    let timeout = window.setTimeout(() => {
      setPopupOpened(false);

      return () => {
        clearTimeout(timeout);
      }
    }, 600)

  }

  const handlerSearch = (ev) => {
    setSearchContent(ev.target.value);

    setProspectDatas([]);

    let timeout = window.setTimeout(() => {
      setProspectDatas(prospectSaves);

      return () => {
        clearTimeout(timeout);
      }
    }, 300)

    //setProspectDatas(prospectSaves);
  }

  useEffect(() => {
    getDatas();

    if (popupOpened) {
      sectionProspectEditor.current.style.display = "flex";

      let timeout = window.setTimeout((ev) => {
        sectionProspectEditor.current.classList.add("visible");
        return () => {
          clearTimeout(timeout);
        }
      }, 10)
    }
  }, [popupOpened]);

  return (
    <>

      <Header getDatas={getDatas} />

      <main className='prospects'>
        <h2>Prospects et clients de La Suite</h2>
        <button className='new-prospect' onClick={openEditor}>
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
          Ajouter un prospect
        </button>
        <input type="text" className='search-bar' placeholder='Chercher un prospect' onChange={handlerSearch} />

        {popupOpened ?
          <section className='prospects-editor' onClick={(ev) => { if (ev.target.tagName === "SECTION") { closeEditor(); } }} ref={sectionProspectEditor}>
            <div className='editor-content'>
              <h3>Nouveau prospect :</h3>
              <ProspectForm prospect={0} getDatas={getDatas} closeEditor={closeEditor} addSuccess={addSuccess} clearDatas={clearDatas} />
            </div>
          </section>
          : ""}
        <ul className='prospects-list'>
          {
            prospectsDatas ?
              Array.isArray(prospectsDatas.content) ?
                prospectsDatas.content?.filter((prospect) => prospect.prenom.toLowerCase().indexOf(searchContent.toLowerCase()) > -1 || prospect.nom.toLowerCase().indexOf(searchContent.toLowerCase()) > -1)
                  .map((prospect, index) => (
                    <Prospect key={index} prospect={prospect} getDatas={getDatas} changePopupState={changePopupState} popupEditorCloseAnimation={popupEditorCloseAnimation} addSuccess={addSuccess} clearDatas={clearDatas} />
                  ))
                : ""
              : ""
          }
        </ul>

        <ul className='success-list'>
          {
            successList?.map((success, index) => (
              success.state !== "outdated" ?
                <Success success={success} key={index} id={index + 1} removeSuccess={removeSuccess} editSuccess={editSuccess} />
                : ""
            ))
          }
        </ul>
      </main>


    </>
  );
};

export default Prospects;