import React from 'react';
import Header from '../components/Header';
import List from '../components/List';
import Success from '../components/Success';

const Past = ({ customers, getDatas, successList, addSuccess, removeSuccess, editSuccess, clearDatas }) => {
  return (
    <>

      <Header getDatas={getDatas} />

      <main className='past'>
        <h2>
          Réservations passées
        </h2>

        <List customers={customers} getDatas={getDatas} addSuccess={addSuccess} clearDatas={clearDatas} />

        <ul className='success-list'>
          {
            successList?.map((success, index) => (
              <Success success={success} key={index} id={index + 1} removeSuccess={removeSuccess} editSuccess={editSuccess} />
            ))
          }
        </ul>
      </main>
    </>
  );
};

export default Past;