import React from 'react';
import Customer from './Customer';

const List = ({ customers, getDatas, addSuccess, clearDatas }) => {
  return (
    <ul className={ customers?.length > 0 ? 'appointments' : 'appointments empty' }>
      { customers ? 
        Array.isArray(customers) ?
        customers?.length > 0 ?
        customers?.map((customer, index) => (
          <Customer customer={customer} key={index} getDatas={getDatas} addSuccess={addSuccess} clearDatas={clearDatas} />
        ))
        :
        <li className='no-appointments'>Aucune réservation dans cette catégorie pour le moment...</li>
        : ""
        : ""
      }
    </ul>
  );
};

export default List;