import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Coming from './pages/Coming';
import Home from './pages/Home';
import Past from './pages/Past';
import Prospects from './pages/Prospects';
import Waiting from './pages/Waiting';
import Remove from './pages/Remove';

const App = () => {

  const [datas, setDatas] = useState({
    error: "The request was not sent - error0",
    coming: [],
    past: [],
    waiting: []

  });
  const [successList, setSuccessList] = useState([]);
  const [logged, setLogged] = useState(null);

  const getDatas = () => {

    axios.get("https://connivence-salon.net/api/lsbdx/get.php")
      .then((res) => {
        if (res.data.success) {
          setDatas(res.data)
        }
        else if (res.data.error) {
          console.log(res.data.error);
        }

      })
  }

  const clearDatas = () => {
    setDatas([]);
  }

  const addSuccess = (success) => {
    const arrayLine = { id: successList.length + 1, content: success, state: "hidden" };
    setSuccessList(oldArray => [...oldArray, arrayLine]);

  }
  const editSuccess = (id, state) => {
    setSuccessList(prevState => {
      const newState = prevState.map(obj => {
        if (obj.id === id) {
          return { ...obj, state: state };
        }

        return obj;
      });

      return newState;
    });
  }
  const removeSuccess = (id) => {
    setSuccessList((state) => state.filter((success) => success.id !== id));
  }

  const loginTry = (passTry) => {
    if (passTry === "Bdx#1423") {
      sessionStorage.setItem('logged', '$2y$10$esvxJcxvSwubvSOcA6pAV.qqPWYG.lwQn.3Ljcw9SIDaCb7wfI9F2');
      setLogged('$2y$10$esvxJcxvSwubvSOcA6pAV.qqPWYG.lwQn.3Ljcw9SIDaCb7wfI9F2');
      return true;
    }
    else {
      return false;
    }
  }

  useEffect(() => {
    getDatas();
    if (!sessionStorage.getItem('logged')) {
      sessionStorage.setItem('logged', 'false');
    }

    if (sessionStorage.getItem('logged') === "$2y$10$esvxJcxvSwubvSOcA6pAV.qqPWYG.lwQn.3Ljcw9SIDaCb7wfI9F2") {
      setLogged('$2y$10$esvxJcxvSwubvSOcA6pAV.qqPWYG.lwQn.3Ljcw9SIDaCb7wfI9F2');
    }
    else {
      setLogged(null);
    }
  }, []);

  return (
    <BrowserRouter>
      {logged === "$2y$10$esvxJcxvSwubvSOcA6pAV.qqPWYG.lwQn.3Ljcw9SIDaCb7wfI9F2" ?
        <Routes>
          <Route path='/' element={<Home customers={datas} getDatas={getDatas} clearDatas={clearDatas} successList={successList} addSuccess={addSuccess} removeSuccess={removeSuccess} editSuccess={editSuccess} />} />
          <Route path='/waiting' element={<Waiting customers={datas.waiting} getDatas={getDatas} clearDatas={clearDatas} successList={successList} addSuccess={addSuccess} removeSuccess={removeSuccess} editSuccess={editSuccess} />} />
          <Route path='/coming' element={<Coming customers={datas.coming} getDatas={getDatas} clearDatas={clearDatas} successList={successList} addSuccess={addSuccess} removeSuccess={removeSuccess} editSuccess={editSuccess} />} />
          <Route path='/past' element={<Past customers={datas.past} getDatas={getDatas} clearDatas={clearDatas} successList={successList} addSuccess={addSuccess} removeSuccess={removeSuccess} editSuccess={editSuccess} />} />
          <Route path='/prospects' element={<Prospects getDatas={getDatas} logged={logged} loginTry={loginTry} successList={successList} addSuccess={addSuccess} removeSuccess={removeSuccess} editSuccess={editSuccess} />} />
          <Route path='/remove' element={<Remove getDatas={getDatas} />} />
          <Route path='*' element={<Home getDatas={getDatas} clearDatas={clearDatas} successList={successList} addSuccess={addSuccess} removeSuccess={removeSuccess} editSuccess={editSuccess} logged={logged} loginTry={loginTry} />} />
        </Routes>
        :
        <Routes>
          <Route path='*' element={<Login logged={logged} loginTry={loginTry} />} />
        </Routes>
      }
    </BrowserRouter>
  );
};

export default App;
