import React from 'react';
import Header from '../components/Header';
import List from '../components/List';
import Success from '../components/Success';

const Home = ({ customers, getDatas, successList, addSuccess, removeSuccess, editSuccess, clearDatas }) => {

  return (
    <>
      <Header getDatas={getDatas} />

      <main>

        <section className='waiting'>
          <h2>
            Demandes de réservation
          </h2>

          <List customers={customers.waiting} getDatas={getDatas} addSuccess={addSuccess} clearDatas={clearDatas} />
        </section>
        <section className='coming'>
          <h2>
            Réservations à venir
          </h2>

          <List customers={customers.coming} getDatas={getDatas} addSuccess={addSuccess} clearDatas={clearDatas} />
        </section>
        <section className='past'>
          <h2>
            Réservations passées
          </h2>

          <List customers={customers.past} getDatas={getDatas} addSuccess={addSuccess} clearDatas={clearDatas} />
        </section>
        <ul className='success-list'>
          {
            successList?.map((success, index) => (
              success.state !== "outdated" ?
                <Success success={success} key={index} id={index + 1} removeSuccess={removeSuccess} editSuccess={editSuccess} />
                : ""
            ))
          }
        </ul>
      </main>
    </>
  );
};

export default Home;