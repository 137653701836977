import React, { useEffect } from 'react';
//import { useState } from 'react';

const Success = ({ success, id, removeSuccess, editSuccess }) => {

  useEffect(() => {

    if(success.state === "hidden"){
      let timeout = window.setTimeout(() => {
        editSuccess(id, "visible");
        return ()=> {
          clearTimeout(timeout);
        }
      }, 100)
    }
    else if(success.state === "visible"){
      let timeout = window.setTimeout(() => {
        editSuccess(id, "fadeout");
        return ()=> {
          clearTimeout(timeout);
        }
      }, 3000)
    }
    else if(success.state === "fadeout"){
      let timeout = window.setTimeout(() => {
        editSuccess(id, "outdated");
        return ()=> {
          clearTimeout(timeout);
        }
      }, 400)
    }
  }, [id, removeSuccess, editSuccess, success])

  return (
    <li className={'success ' + success.state}>
      {success.content}
    </li>
  );
};

export default Success;