import { faInfo, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ProspectForm from './ProspectForm';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

const Prospect = ({ prospect, getDatas, changePopupState, popupEditorCloseAnimation, addSuccess, clearDatas }) => {

  const [popupOpened, setPopupOpened] = useState(false);
  const [mailEditorOpened, setMailEditorOpened] = useState(false);
  const [mailContent, setMailContent] = useState();

  const sectionProspectEditor = useRef();

  const sectionMailEditor = useRef();

  const sendMail = () => {
    axios.get("https://connivence-salon.net/api/lsbdx/prospects/mail.php?id=" + prospect.id + "&content=" + encodeURIComponent(mailContent))
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
        }
        else if (res.data.success) {
          console.log(res.data.success);
          closeEditor(sectionMailEditor.current);
          clearDatas();
          getDatas();

          addSuccess("Le mail ainsi que la plaquette de présentation ont été envoyés à " + prospect.nom.toUpperCase() + " " + prospect.prenom + " !");
        }

      })
  }

  const handlerSend = (ev) => {
    ev.preventDefault();

    if (prospect.sent === "1") {
      if (window.confirm("Vous avez déjà envoyé la plaquette par mail à " + prospect.nom.toUpperCase() + " " + prospect.prenom + ". Voulez-vous quand même la renvoyer ?")) {
        sendMail();
      }
    }
    else {
      sendMail();
    }
  }

  const handlerDelete = (ev) => {
    if (window.confirm("Voulez-vous vraiment supprimer la fiche prospect de " + prospect.nom.toUpperCase() + " " + prospect.prenom + " ?")) {
      axios.get("https://connivence-salon.net/api/lsbdx/prospects/delete.php?id=" + prospect.id)
        .then((res) => {
          if (res.data.error) {
            console.log(res.data.error);
          }
          else if (res.data.success) {
            console.log(res.data.success);
            clearDatas();
            getDatas();

            addSuccess("La fiche prospect de " + prospect.nom.toUpperCase() + " " + prospect.prenom + " a bien été supprimée !");
          }

        })
    }
  }

  const openEditor = (el) => {

    let popupResponse = changePopupState();

    if (popupResponse === true) {
      setPopupOpened(true);
    }

  }

  const closeEditor = (el) => {
    popupEditorCloseAnimation(el);

    let timeout = window.setTimeout(() => {
      if (el === sectionProspectEditor.current) {
        setPopupOpened(false);
      }
      else if (el === sectionMailEditor.current) {
        setMailEditorOpened(false);
      }


      return () => {
        clearTimeout(timeout);
      }
    }, 600)

  }

  useEffect(() => {
    let timeout;
    if (!mailContent) {
      let mailTmp = "Bonjour Madame,\n\nJe vous transmets la plaquette du salon privé de La Suite CONNIVENCE.\nJe me tiens personnellement à votre disposition pour toute question y compris d’ordre purement technique. Surtout n’hésitez pas à me contacter par mail ou par téléphone (des photos disons plus généralement des éléments pour personnaliser votre style seront les bienvenus). \n\nEt si vous ressentez le besoin de venir me rencontrer dans La Suite sans que cela vous oblige à quoi que ce soit, là également je me ferai un plaisir d’être à votre disposition.\n\nVous trouverez sur le site internet « lasuiteconnivence.net » mes jours de présence.";
      setMailContent(mailTmp);
    }
    if (popupOpened) {
      sectionProspectEditor.current.style.display = "flex";

      timeout = window.setTimeout((ev) => {
        sectionProspectEditor.current.classList.add("visible");
        return () => {
          clearTimeout(timeout);
        }
      }, 10)
    }
    if (mailEditorOpened) {
      sectionMailEditor.current.style.display = "flex";

      timeout = window.setTimeout((ev) => {
        sectionMailEditor.current.classList.add("visible");
        return () => {
          clearTimeout(timeout);
        }
      }, 10)
    }
  }, [popupOpened, mailEditorOpened, prospect, mailContent])

  return (
    <li>
      <div>
        <p><strong>{prospect.nom.toUpperCase()} {prospect.prenom}</strong></p>
        <ul className='prospects-actions'>
          <li>
            <button onClick={openEditor}>
              <FontAwesomeIcon icon={faInfo} />
            </button>
          </li>
          {prospect.link ?
            <li>
              <a href={'https://connivence-salon.net/fiche.php?id=' + prospect.link} rel='noreferrer' target='_blank'>
                <FontAwesomeIcon icon={faUpRightFromSquare} />
              </a>
            </li>
            : ""}
          {prospect.mail ?
            <li>
              <button onClick={() => setMailEditorOpened(true)}>
                <FontAwesomeIcon icon={faEnvelope} />
              </button>
            </li>
            : ""}
          <li className='trash'>
            <button onClick={handlerDelete}>
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </li>
        </ul>

      </div>
      {prospect.commentaire?.length > 0 ?
        <p className='commentaire'>{prospect.commentaire}</p>
        : ""}

      {popupOpened ?

        <section className='prospects-editor' onClick={(ev) => { if (ev.target.tagName === "SECTION") { closeEditor(sectionProspectEditor.current); } }} ref={sectionProspectEditor}>
          <div className='editor-content'>
            <h3>Informations du prospect :</h3>
            <ProspectForm prospect={prospect} getDatas={getDatas} closeEditor={closeEditor} addSuccess={addSuccess} clearDatas={clearDatas} />
          </div>
        </section>

        : ""}

      {mailEditorOpened ?

        <section className='mail-editor' onClick={(ev) => { if (ev.target.tagName === "SECTION") { closeEditor(sectionMailEditor.current); } }} ref={sectionMailEditor}>
          <div className='editor-content'>
            <h3>Mail de prospection :</h3>
            <p>L'objet, le design du mail ainsi que la plaquette en pièce jointe seront générés automatiquement à l'envoi.</p>
            <form onSubmit={handlerSend}>
              <div className='form-content full-width'>
                <textarea name='mail' onChange={(ev) => setMailContent(ev.target.value)} defaultValue={mailContent}></textarea>
                <input type='hidden' name='id' value={prospect.id} />
              </div>
              <input type='submit' value="Envoyer" />
            </form>
          </div>
        </section>

        : ""}
    </li>
  );
};

export default Prospect;