import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheckDouble, faHistory, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';



const Header = ({ getDatas }) => {
  return (
    <header>
      <img src='logo_rouge.png' alt="Logo Connivence" />
      <h1>
        La Suite Connivence
      </h1>
      <nav>
        <ul>
          <NavLink to="/" className={(nav) => nav.isActive ? "nav-active all" : "all"} onClick={getDatas}>
            <li>
              Tout
            </li>
          </NavLink>
          <NavLink to="/waiting" className={(nav) => nav.isActive ? "nav-active" : ""} onClick={getDatas}>
            <li>
              <FontAwesomeIcon icon={faHourglassHalf} />
            </li>
          </NavLink>
          <NavLink to="/coming" className={(nav) => nav.isActive ? "nav-active" : ""} onClick={getDatas}>
            <li>
              <FontAwesomeIcon icon={faCheckDouble} />
            </li>
          </NavLink>
          <NavLink to="/past" className={(nav) => nav.isActive ? "nav-active" : ""} onClick={getDatas}>
            <li>
              <FontAwesomeIcon icon={faHistory} />
            </li>
          </NavLink>
          <NavLink to="/prospects" className={(nav) => nav.isActive ? "nav-active" : ""}>
            <li>
              <FontAwesomeIcon icon={faUsers} />
            </li>
          </NavLink>
          <NavLink to="/remove" className={(nav) => nav.isActive ? "nav-active" : ""}>
            <li>
              <FontAwesomeIcon icon={faBan} />
            </li>
          </NavLink>
        </ul>
      </nav>
    </header>
  );
};

export default Header;